<template>
  <v-container>
    <v-row class="px-3" v-show="listaMetas.length != 0 && listaObjectives.length != 0">
      <v-col cols="4" align-self="center" class="pa-0">
        <div id="select_st">
          <v-select
            @change="getObjectiveOptions()"
            v-model="selectedGoalId"
            :items="listaMetas"
            item-text="name"
            item-value="id"
            label="Goals"
            class="ip_sl"
            solo
            dense
            primary
            flat="flat"
          ></v-select>
        </div>
      </v-col>
      <v-col cols="4" align-self="center" class="pa-0">
        <div id="select_st">
          <v-select
            @change="getLinks()"
            v-model="selectedObjectiveId"
            :items="objectivesToShow"
            item-text="name"
            item-value="id"
            label="Objectives"
            class="ip_sl"
            solo
            dense
            primary
            flat="flat"
          ></v-select>
        </div>
      </v-col>
    </v-row>
    <template v-if="selectedObjectiveId">
      <v-row class="px-3 goalsTitle"> Video 1 </v-row>
      <v-row class="px-3 pb-3 goalsSubTitle">Duration: 1 min.</v-row>
      <v-container class="pa-0">
        <v-row>
          <v-col cols="7">
            <iframe
              :class="videoGoals"
              v-if="selectedVideo?.link_iframe"
              width="550"
              height="330"
              :src="selectedVideo.link_iframe"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-col>
          <v-col cols="5">
            <p class="p-description">Descripción</p>
            <div>
              <div v-if="selectedObjectiveId == 15">
                <SocialProfileLaunch></SocialProfileLaunch>
              </div>
              <div v-if="selectedObjectiveId == 16">
                <AudienceCampaign></AudienceCampaign>
              </div>
              <div v-if="selectedObjectiveId == 17">
                <DesignOfMarketingPieces></DesignOfMarketingPieces>
              </div>
              <div v-if="selectedObjectiveId == 18"><Mercado></Mercado></div>
              <div v-if="selectedObjectiveId == 19"><Marca></Marca></div>
              <div v-if="selectedObjectiveId == 20">
                <ProductoServicio></ProductoServicio>
              </div>
            </div>
            <label class="label-desc"> Mas Información </label>
            <div class="p-desc">Brinda más información de ser necesario</div>
            <textarea class="txtB_ pa-2" rows="5" v-model="content"> </textarea>
            <v-row justify="end" class="mt-5 pr-5">
              <open-ticket
                :brief="brief"
                :objective="selectedObjectiveId"
                :description="content"
                @sendData="sendData($event)"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <thank-request ref="thank" />
  </v-container>
</template>

<script>
import {
  getGoals,
  getObjectives,
  getObjectiveVideos,
  getObjectGoals
} from "../../services/services";
import OpenTicket from "./OpenTicket.vue";
import ThankRequest from "./ThankRequest.vue";
import AudienceCampaign from "../objectives/campanas/audienceCampaign.vue";
import DesignOfMarketingPieces from "../objectives/campanas/designOfMarketingPieces.vue";
import SocialProfileLaunch from "../objectives/campanas/socialProfileLaunch.vue";
import Marca from "../objectives/analisis/marca.vue";
import Mercado from "../objectives/analisis/mercado.vue";
import ProductoServicio from "../objectives/analisis/productoServicio.vue";

export default {
  name: "Goals",
  components: {
    OpenTicket,
    ThankRequest,
    SocialProfileLaunch,
    AudienceCampaign,
    DesignOfMarketingPieces,
    Marca,
    Mercado,
    ProductoServicio,
  },
  data() {
    return {
      selectedGoalId: 0,
      selectedObjectiveId: 0,
      objectivesToShow: [],
      content: "",
      listaMetas: [],
      listaObjectives: [],
      lstVideos: [],
      selectedVideo: null,
    };
  },
  computed: {},
  props: ["brief"],
  methods: {
    async sendData(daysAdd) {
      await this.$refs.thank.open(daysAdd);
      this.selectedGoalId = 0;
      this.selectedObjectiveId = 0;
      this.content = "";
      this.selectedVideo = null;
      // this.$emit("resetData");
      this.$router.push("ticket-status");
    },
    getObjectiveOptions() {
      this.selectedVideo = null;
      this.selectedObjectiveId = 0;
      this.objectivesToShow = this.listaObjectives.filter(
        (objective) => objective.goal_id == this.selectedGoalId
      );
    },
    getLinks() {
      this.selectedVideo = this.lstVideos.find(
        ({ objective_id }) => objective_id == this.selectedObjectiveId
      );
    },
  },
  async created() {
    this.listaMetas = await getGoals();
    this.listaObjectives = await getObjectives();
    this.lstVideos = await getObjectiveVideos();

  },
  watch: {},
};
</script>

<style scoped>
.txtB_ {
  font-family: "Oxygen";
  font-style: normal;
  font-size: 12px;
  width: 100%;
  background: #ffff;
  /* border: solid 1px #2c3852; */
  border: 1px solid #c5d0e4;
  border-radius: 3px;
  max-height: 58px;
}
.txtB_:focus {
  outline: none !important;
  border: 1px solid #00a5ff;
}

.btn-sgt {
  color: white !important;
  text-transform: none !important;
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
}

.a-title {
  font-weight: bold;
  margin-bottom: 4px;
  color: #2c3852;
}

.a-subtitle {
  color: #2c3852;
}

#select_st {
  display: flex;
  margin: 20px 0;
}

#select_st >>> .ip_sl {
  width: 100%;
  margin-right: 15px;
  flex: inherit;
  height: 35px;
  margin-top: 0;
  padding: 0;
  /* &:last-child {
        margin-right: 0;
    } */
}

#select_st >>> .v-input__slot {
  background: #fff;
  height: 40px;
  padding-left: 10px;
  border: solid 1px #c7cfe2 !important;
  box-shadow: inset 0px 1px 3px 1px rgba(72, 72, 72, 0.18);
  border-radius: 3px;
}
#select_st >>> .v-input__slot::before {
  border: none !important;
}
#select_st >>> .v-input__slot::after {
  border: none !important;
}

#select_st >>> .v-label {
  color: #5d6b88;
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

#select_st >>> .v-label--active {
  display: none;
}
#select_st >>> .v-select__selection {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.label-desc {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #2c3852;
}
.p-desc {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  max-height: 105px;
  color: #5d6b88;
  overflow-y: auto;
}

.p-description {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 164.19%;
  color: #2c3852;
}

.goalsTitle {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 164.19%;
  color: #2c3852;
}

.goalsSubTitle {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 10.5px;
  line-height: 164.19%;
  color: #5d6b88;
}

@media (max-width: 1300px) {
  .videoGoals {
    width: 50%;
    height: 80%;
  }

  .container-goals .container .row .col iframe{
    width: 100%;
  }
}
</style>
