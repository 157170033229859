<template>
  <div>
    <div>
      <p class="p-content">
        Las piezas de Marketing Digitales tienen un papel fundamental dentro de
        la estrategia en las Redes Sociales de todas las empresas. Es esencial
        usar las piezas adecuadas con el contenido correcto en la Red Social
        ideal para tener éxito en el objetivo trazado. En este informe se
        presenta un análisis de la empresa y su entorno, se sugiere una
        estrategia a seguir diseñada por expertos y se presenta un plan de
        acción donde se sugieren una serie de temas, formatos y una línea
        gráfica personalizada a la empresa y el posicionamiento que desea.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "designOfMarketingPieces",
};
</script>
<style src="../styleObjectives.css" scoped />
