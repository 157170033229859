<template>
  <div>
    <div>
      <p class="p-content">
        Las campañas en Redes Sociales son una serie de acciones realizadas para
        conseguir unos objetivos concretos a través de los canales sociales de
        la empresa. La manera de llevar a cabo esas acciones de manera correcta
        es a través de una buena estrategia o plan. En este informe se presenta
        detalladamente un análisis de la empresa y su entorno, se sugiere una
        estrategia a seguir diseñada por expertos y se presenta un plan de
        acción a implementar de acuerdo a la campaña que se quiera realizar
        teniendo en cuenta el objetivo específico que se desee lograr.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "audienceCampaign",
};
</script>
<style src="../styleObjectives.css" scoped />
