<template>
  <div style="height: 100%;">
    <v-container class="my-8" fluid>
      <v-row justify="center">
        <v-col cols="10">
          <v-tabs
            v-model="tabs"
            grow
            hide-slider
            height="55px"
            background-color="#F3F4F7"
            class="container-active-tab container-tab-brief-goals"
          >
            <div class="container-triangle">
              <v-tab
                id="active-tab--first"
                class="justify-start get-help-tab mb-2 arrow_box company-brief"
                :key="1"
                active-class="active-tab"
                style="height: 100%"
              >
                <span class="rounded" v-if="!updateMode">1</span>
                <span class="rounded goals" v-else>
                  <v-icon size="14" dark right class="ml-0" color="#00a5ff">
                    fas fa-check
                  </v-icon>
                </span>
                Company Brief
              </v-tab>
            </div>
            <div class="container-triangle">
              <v-tab
                class="justify-start get-help-tab mb-2 goals-definition"
                :key="2"
                active-class="active-tab"
                :disabled="idBrief == 0"
                style="height: 100%"
              >
                <span class="rounded goals">2</span> Goals Definition
              </v-tab>
            </div>
          </v-tabs>
          <v-tabs-items v-model="tabs" class="container-tab-item">
            <!-- COMPANY BRIEF -->

            <v-tab-item :key="1" class="mt-5">
              <!-- <company-brief v-on:update-mode="changeUpdateMode" /> -->
              <company-brief
                ref="company"
                :idBrief="idBrief"
                :brief="brief"
                @nextTab="nextTab($event)"
              />
            </v-tab-item>
            <!-- GOALS DEFINITION -->
            <v-tab-item :key="2" class="container-goals">
              <goals
                :brief="brief"
                @resetData="
                  reset();
                  $refs.company.toSteep(1);
                "
                class="container-goals pa-0"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Goals from "../components/goals/Goals.vue";
import CompanyBrief from "../components/brief/CompanyBrief.vue";
import { getBrief } from "./../services/services";

export default {
  components: { Goals, CompanyBrief },
  async created() {
    this.reset();
    const clientId = sessionStorage.getItem("client_id");
    const data = await getBrief(clientId);
    if (data.length > 0) {
      if (this.isJsonString(data[0].brief)) {
        this.brief = JSON.parse(data[0].brief);
      }
      this.idBrief = data[0].id;
    }   
  },
  data() {
    return {
      tabs: 0,
      brief: {},
      idBrief: 0,
      isScreensizeLess: false,
    };
  },
  methods: {
    nextTab(idBrief) {
      this.idBrief = idBrief;
      this.tabs += 1;
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    reset() {
      this.tabs = 0;
      this.brief = {
        coordinates: [-76.6816110334773, -11.98484584997],
        nombreCompania: "",
        email: "",
        direccion: "",
        ciudad: "",
        contacto: "",
        descripcion: 0,
        categoria: 0,
        descripcionProductosPrincipales: "",
        tamanioEmpresa: 0,
        cantidadEmpleados: 0,
        mercado: 0,
        empresas: [{ name: "" }],
        competencias: [
          {
            redSocial: "",
            linkWeb: "",
          },
        ],
        pais: 0,
        lstPaises: [],
        lstDepartamentos: [],
        locales: [
          {
            departamento: 0,
            provincia: 0,
            distrito: 0,
            lstProvincias: [],
            lstDistritos: [],
          },
        ],
        marketing: "b2b",
        perfilB2B: {
          empresa: "",
          sectores: [],
          otros: false,
          descOtros: "",
        },
        perfilB2C: {
          empresa: "",
          genero: "",
          edad: "",
          ubicacion: "",
          necesidades: "",
          motivaciones: "",
          habitos: "",
          nse: "",
          habitosSocialMedia: "",
        },
        gerencias: [],
        adopcionTransformacionDigital: [],
        numeroEmpleadosCapacitadosRRSS: "",
        existeArea: "",
        numeroEmpleadosArea: "",
        redesSociales: [
          {
            nombre: "",
            link: "",
          },
        ],
        website: "",
        campaniasRealizadas: [],
        montoPromedioPublicidad: "",
        fortalezas: [],
        otrasFortalezas: false,
        otrasFortalezasDesc: "",
        debilidades: [],
        otrasDebilidades: false,
        otrasDebilidadesDesc: "",
        aspectoDiferencial: "",
        puntosClave: "",
        objetivosInforme: "",
      };
    },
  },
};
</script>

<style scoped>
.container-tab-item {
  background-color: unset;
}
.get-help-container {
  max-width: 1400px !important;
}

.rounded {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50% !important;
  text-align: center;
  display: inline-block;
  margin-right: 15px;
  background-color: #f3f4f8;
}

#active-tab--first:not(.active-tab) {
  background: #00a5ff !important;
  color: white !important;
}
#active-tab--first:not(.active-tab) .rounded {
  background-color: white;
  color: #00a5ff;
}

.company-brief {
  clip-path: polygon(0% 0%, 96% 0%, 100% 50%, 96% 100%, 0% 100%);
  margin-left: -20px;
  left: 10px;
  padding-left: 30px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1) !important;
  font-family: 'Oxygen';
  letter-spacing: 0px;
}

.goals-definition {
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 4% 50%, 0% 0%);
  right: 10px;
  margin-right: -20px;
  letter-spacing: 0px;
}

/* .company-brief {
  
}

.goals-definition {
  width: 50%;
  margin-left: 0.8rem;
} */

.get-help-tab {
  z-index: 4;
  text-transform: none;
  font-weight: bold;
  box-shadow: 0px 3px 5px rgb(182, 181, 181);
  background-color: #fff;
}
.get-help-tab:not(.v-tab--active) {
  color: #2c3852 !important;
  opacity: 1 !important;
}
.active-tab {
  background-color: #00a5ff;
  color: white !important;
}

.active-tab .rounded {
  background-color: #f3f4f7;
  color: #00a5ff;
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700;
  font-size: 12.5px;
}
.goals {
  margin-left: 18px;
}

.container-triangle {
  width: 50%;
  height: 80%;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 10%);
}
</style>
