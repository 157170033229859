<template>
  <div>
    <div>
      <p class="p-content">
        La reputación de marca es la percepción que tienen de una determinada
        marca los consumidores.En este estudio se realiza un análisis completo
        de marca.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "audienceCampaign",
};
</script>
<style src="../styleObjectives.css" scoped />
