<template>
  <div>
    <div>
      <p class="p-content">
        Las Redes Sociales de todas las empresas se han convertido en un aspecto
        fundamental dentro de su actividad comercial; Sin embargo, se deben
        manejar estratégicamente para obtener resultados exitosos. En este
        informe se presenta detalladamente un análisis de la empresa y su
        entorno, se sugiere una estrategia a seguir diseñada por expertos y se
        presenta un plan de acción a implementar para el lanzamiento de un
        Perfil Social.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "socialProfileLaunch"
}
</script>
<style src="../styleObjectives.css" scoped />