
<template>
  <v-dialog
    v-model="dialog"
    :width="options.width"
    :height="260"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    content-class="dialog-thanks"
    persistent
  >
    <v-card class="card-thanks" height="260px">
      <div class="div-close mt-5">
        <v-icon color="white" @click="cancel">mdi-close-thick</v-icon>
      </div>

      <v-card-title class="title mt-10 pa-0">
        Thank you for you request
      </v-card-title>
      <v-row justify="center" class="mt-7">
        <svg
          width="63"
          height="74"
          viewBox="0 0 63 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.5 57.0489L13.5244 48.0141V28.0513L31.5 37.0862V57.0489Z"
            fill="#FFBE28"
          />
          <path
            d="M63 45.1744V20.5221L54.3546 25.5128V51.1547L47.036 54.8116V61.9535L39.9314 58.3826L31.5 62.6419L8.64538 51.1547V20.5221L31.5 9.03488L54.3546 20.1349L63 15.1872L31.5 0L0 15.1872V56.5326L31.5 72.3651L41.3438 67.4174L54.483 74V60.7919L63 56.5326C62.9572 56.5326 63 46.6372 63 45.1744Z"
            fill="white"
          />
        </svg>
      </v-row>
      <v-row class="mt-8 text-card">
        We will answer your request by {{ getDate() }}
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      daysAdd: 1,
      options: {
        color: "grey lighten-3",
        width: 700,
        zIndex: 200,
        noconfirm: false,
      },
    };
  },
  methods: {
    open(daysAdd) {
      this.dialog = true;
      this.daysAdd = daysAdd;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cancel() {
      this.resolve(true);
      this.dialog = false;
    },
    getDate() {
      const now = new Date();
      now.setDate(now.getDate() + this.daysAdd);
      return now.toLocaleString("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      });
    },
  },
};
</script>


<style scoped>
.card-thanks {
  background: #00a5ff !important;
  overflow-x: hidden;
  color: white !important;
}
.title {
  font-family: "Oxygen" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.text-card {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 164.19%;
  text-align: center;
  display: flex;
  justify-content: center;
}
.div-close {
  left: 663px;
  position: absolute;
  display: flex;
  color: white;
  cursor: pointer;
}
</style>