<template>
  <div>
    <div>
      <p class="p-content">
        El análisis del producto hace referencia al estudio detallado que se
        realiza sobre un producto. ... En este caso el análisis se hace sobre
        el producto. Un producto es cualquier bien o servicio producido por una
        empresa con el fin de cubrir una necesidad de los consumidores.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "audienceCampaign",
};
</script>
<style src="../styleObjectives.css" scoped />
