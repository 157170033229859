<template>
  <div>
    <div>
      <p class="p-content">
        Un estudio de comunidades permite conocer los grupos de conversación que
        se forman en torno a una marca, producto o interés, como un evento o un
        tema que sean tendencia en un momento determinado. Con este estudio
        podrás obtener un informe completo de los usuarios que forman cada
        comunidad, cómo interactúan entre ellos o qué aspectos tienen más
        repercusión, entre otros parámetros analizables. Conocer estos datos te
        aportará una visión estratégica para desarrollar una comunidad sólida y
        una difusión óptima de tu marca.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "audienceCampaign",
};
</script>
<style src="../styleObjectives.css" scoped />
