import axios from 'axios'
axios.interceptors.request.use(
  function (config) {
    config.headers['Authorization'] = `Bearer ${sessionStorage.getItem(
      'token',
    )}|${sessionStorage.getItem('refresh')}|${sessionStorage.getItem(
      'ip_public',
    )}|${sessionStorage.getItem('ip_local')}`
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)
// const adviseApi = process.env.VUE_APP_ADVISE_API_LOCAL
const adviseApi = process.env.VUE_APP_ADVISE_API

export const getCountries = async () => {
  try {
    const { data } = await axios.get(`${adviseApi}/brief/country`)
    return data
  } catch (error) {
    return []
  }
}

export const getDepartaments = async (countryId) => {
  try {
    const { data } = await axios.get(
      `${adviseApi}/brief/department?country_id=${countryId}`,
    )
    return data
  } catch (error) {
    return []
  }
}

export const getProvinces = async (departmentId) => {
  try {
    const { data } = await axios.get(
      `${adviseApi}/brief/province?department_id=${departmentId}`,
    )
    return data
  } catch (error) {
    return []
  }
}

export const getDistricts = async (provinceId) => {
  try {
    const { data } = await axios.get(
      `${adviseApi}/brief/district?province_id=${provinceId}`,
    )
    return data
  } catch (error) {
    return []
  }
}

export const getGoals = async () => {
  try {
    const { data } = await axios.get(`${adviseApi}/objective/goal`)
    return data
  } catch (error) {
    return []
  }
}

export const getObjectives = async () => {
  try {
    const { data } = await axios.get(`${adviseApi}/objective/objective`)
    return data
  } catch (error) {
    return []
  }
}

export const getObjectiveVideos = async () => {
  try {
    const { data } = await axios.get(`${adviseApi}/objective/objective-video`)
    return data
  } catch (error) {
    return []
  }
}

export const getKindsTicket = async () => {
  try {
    const { data } = await axios.get(`${adviseApi}/ticket/kin`)
    return data.filter(kin => kin.active === true)
  } catch (error) {
    return []
  }
}

export const getReasonsTicket = async () => {
  try {
    const { data } = await axios.get(`${adviseApi}/ticket/reason`)
    return data
  } catch (error) {
    return []
  }
}

export const getConceptsTicket = async () => {
  try {
    const { data } = await axios.get(`${adviseApi}/ticket/concept`)
    return data
  } catch (error) {
    return []
  }
}

export const postBrief = async (data) => {
  try {
    const response = await axios.post(`${adviseApi}/brief/brief`, data)
    return response
  } catch (error) {
    return false
  }
}

export const getBrief = async (userId) => {
  try {
    const { data } = await axios.get(
      `${adviseApi}/brief/brief?user_id=${userId}`,
    )
    return data
  } catch (error) {
    return false
  }
}

export const putBrief = async (id, data) => {
  try {
    const response = await axios.put(`${adviseApi}/brief/brief/${id}`, data)
    return response.data
  } catch (error) {
    return false
  }
}

export const postTicket = async (data) => {
  try {
    const response = await axios.post(`${adviseApi}/ticket/ticket`, data)

    return response.data
  } catch (error) {
    return false
  }
}
