<template>
  <v-card color="white" flat>
    <v-form>
      <v-container fluid>
        <v-row no-gutters>
          <v-col>
            <h4 class="sub-title">1. FODA: <span class="obl_">*</span></h4>
          </v-col>
        </v-row>

        <v-container class="py-0">
          <v-row no-gutters>
            <v-col>
              <label style="color: #2c3852">
                Fortalezas de la Empresa: <span class="obl_">*</span>
              </label>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="auto"
              v-for="(fortaleza, idx) in listaFortalezas"
              :key="idx"
              class="py-1"
            >
              <v-checkbox
                class="radio-opacity"
                :label="fortaleza.name"
                v-model="brief.fortalezas"
                :value="fortaleza.name"
                color="#00A5FF"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="1">
                  <v-checkbox
                    class="radio-opacity"
                    label="Otros"
                    v-model="brief.otrasFortalezas"
                    color="#00A5FF"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="5">
                  <input
                    :disabled="!brief.otrasFortalezas"
                    class="inpt_"
                    type="text"
                    v-model="brief.otrasFortalezasDesc"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label>
                Debilidades de la Empresa: <span class="obl_">*</span></label
              >
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="auto"
              v-for="(debilidad, idx) in listaDebilidades"
              :key="idx"
              class="py-1"
            >
              <v-checkbox
                class="radio-opacity"
                :label="debilidad.name"
                v-model="brief.debilidades"
                :value="debilidad.name"
                color="#00A5FF"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="1">
                  <v-checkbox
                    class="radio-opacity"
                    label="Otros"
                    color="#00A5FF"
                    v-model="brief.otrasDebilidades"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="5">
                  <input
                    :disabled="!brief.otrasDebilidades"
                    class="inpt_"
                    type="text"
                    v-model="brief.otrasDebilidadesDesc"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-row class="mt-5">
          <v-col>
            <h4 class="sub-title">
              2. Análisis del Posicionamiento: <span class="obl_">*</span>
            </h4>
          </v-col>
        </v-row>
        <v-container>
          <v-row no-gutters>
            <v-col>
              <label>
                ¿Cuál es el aspecto diferencial de su marca o empresa?
                <span class="obl_">*</span>
              </label>
              <textarea
                class="txtA_"
                cols="10"
                rows="5"
                v-model="brief.aspectoDiferencial"
              >
              </textarea>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <label>
                Enumere todos los puntos clave destacados dentro de su empresa
                <span class="obl_">*</span>
              </label>
              <textarea
                class="txtA_"
                cols="10"
                rows="5"
                v-model="brief.puntosClave"
              >
              </textarea>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <label>
                Objetivo: Defina detalladamente el objetivo o la respuesta que
                desea obtener del informe
                <span class="obl_">*</span>
              </label>
              <textarea
                class="txtA_"
                cols="10"
                rows="5"
                v-model="brief.objetivosInforme"
              >
              </textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "AnalisisFoda",
  components: {},
  data() {
    return {
      listaFortalezas: [
        {
          name: "Política de precios agresiva",
          selected: false,
        },
        {
          name: "Orientación a 'Calidad Total' del producto/servicio",
          selected: false,
        },
        {
          name: "Buen posicionamiento de la marca",
          selected: false,
        },
        {
          name: "Buena percepción de la empresa por el cliente",
          selected: false,
        },
        {
          name: "Trayectoria de más de 5 años",
          selected: false,
        },
        {
          name: "Valor agregado o característica diferenciadora",
          selected: false,
        },
      ],
      listaDebilidades: [
        {
          name: "Poco stock de producto",
          selected: false,
        },
        {
          name: "Disponibilidad limitada del servicio",
          selected: false,
        },
        {
          name: "Poca interacción con los clientes en el Canal Digital",
          selected: false,
        },
        {
          name: "Déficit de confiabilidad en servicios",
          selected: false,
        },
        {
          name: "Déficit de cadena logística",
          selected: false,
        },
        {
          name: "No se tiene canal digital",
          selected: false,
        },
      ],
      fortalezas: [],
      debilidades: [],
    };
  },
  computed: {},
  methods: {},
  props: ["brief"],
  created() {},
  watch: {},
};
</script>

<style src="./brief.css"  scoped>
</style>
